// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beneficios-en-la-salud-js": () => import("../src/pages/beneficios-en-la-salud.js" /* webpackChunkName: "component---src-pages-beneficios-en-la-salud-js" */),
  "component---src-pages-ciudad-de-mexico-js": () => import("../src/pages/ciudad-de-mexico.js" /* webpackChunkName: "component---src-pages-ciudad-de-mexico-js" */),
  "component---src-pages-contacto-js": () => import("../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-gracias-js": () => import("../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tratamiento-a-distancia-js": () => import("../src/pages/tratamiento-a-distancia.js" /* webpackChunkName: "component---src-pages-tratamiento-a-distancia-js" */)
}

