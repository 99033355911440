module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Autohemoterapia: beneficios, qué es y cómo funciona | Hemovacuna®","short_name":"Autohemoterapia: beneficios, qué es y cómo funciona | Hemovacuna®","start_url":"/","background_color":"#E4002B","theme_color":"#E4002B","display":"standalone","icon":"src/images/icono.png","orientation":"portrait"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-150522236-4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
